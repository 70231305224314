import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container, Grid, Divider, Accordion, AccordionSummary, AccordionDetails, Typography} from "@material-ui/core";
import Layout from "../components/Layout";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FixedNavbar from "../components/FixedNavbar";
import Footer from "../components/Footer";
import showdown from 'showdown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  accordianBackground:{
    boxShadow:"none",
    borderBottom: "1px solid #eee"
  },
  accordianHeading:{
    padding:0,
  },
  accordianBody:{
    padding:"0px 0px 16px",
  },
  accordianBodyText:{
    "& p":{
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '0.9rem',
      lineHeight: '140%',
      marginBottom:'16px',
      color:"#666"
    },
    "& ul":{
      marginTop:"18px",
    },
    "& ul li":{
      fontFamily: 'Arial, Helvetica, sans-serif',
      fontSize: '0.9rem',
      lineHeight: '140%',
      marginBottom:'16px',
      color:"#666"
    }
  },
  accordianImage:{
    width: "80%",
    margin: "0 10%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#42513f'
  },
  headingNoBio: {
    padding:"17px 0",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: '#42513f',
    borderBottom: "1px solid #eee"
  },

  
}));

// eslint-disable-next-line
export const PastHighSheriffsPageTemplate = ({ heading, currenthighsheriffimage, pasthighsheriffs21CenturyFeatured, pasthighsheriffs21Century, pasthighsheriffs20Century, pasthighsheriffs19Century, pasthighsheriffs18Century, pasthighsheriffs1417Century }) => {
  const classes = useStyles();
  const converter = new showdown.Converter();

  return (
    <>
      <FixedNavbar />
      <section >
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="sectionHeaderCenter">
              <h2>{heading}</h2>
              <Divider />
            </Grid>
          </Grid>
          <Grid container>
            {pasthighsheriffs21CenturyFeatured.map((sher, index) => (
              <Grid item xs={12} key={index}>
                <Accordion className={classes.accordianBackground}>
                  <AccordionSummary
                    className={classes.accordianHeading}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{sher.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordianBody}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                      <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(sher.bio) }} />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <GatsbyImage placeholder="blurred" image={getImage(sher.image)} className={classes.accordianImage} />
                      </Grid>
                    </Grid>
                    
                  </AccordionDetails>
                </Accordion>             
              </Grid>
            ))}
          </Grid>
          <Divider className="pastDivider"/>
          <Grid container>
            <Grid item xs={12} className="pastSheriffsSection">
              <h4>
                21st Century
              </h4>
              <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(pasthighsheriffs21Century) }} />
            </Grid>
          </Grid>
          <Divider className="pastDivider"/>
          <Grid container>
            <Grid item xs={12} className="pastSheriffsSection">
              <h4>
                20th Century
              </h4>
              <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(pasthighsheriffs20Century) }} />
            </Grid>
          </Grid>
          <Divider className="pastDivider"/>
          <Grid container>
            <Grid item xs={12} className="pastSheriffsSection">
              <h4>
                19th Century
              </h4>
              <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(pasthighsheriffs19Century) }} />
            </Grid>
          </Grid>
          <Divider className="pastDivider"/>
          <Grid container>
            <Grid item xs={12} className="pastSheriffsSection">
              <h4>
                18th Century
              </h4>
              <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(pasthighsheriffs18Century) }} />
            </Grid>
          </Grid>
          <Divider className="pastDivider"/>
          <Grid container>
            <Grid item xs={12} className="pastSheriffsSection">
              <h4>
                14th - 17th Century
              </h4>
              <div className={classes.accordianBodyText} dangerouslySetInnerHTML={{ __html: converter.makeHtml(pasthighsheriffs1417Century) }} />
            </Grid>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
};

PastHighSheriffsPageTemplate.propTypes = {
  heading: PropTypes.string.isRequired,
};

const PastHighSheriffsPage = ({ data }) => {

  const { markdownRemark: post } = data;

  return (
    <Layout>
      <PastHighSheriffsPageTemplate 
        heading={post.frontmatter.heading} 
        currenthighsheriffname={post.frontmatter.currenthighsheriffname} 
        currenthighsheriffimage={post.frontmatter.currenthighsheriffimage} 
        pasthighsheriffs21CenturyFeatured={post.frontmatter.pasthighsheriffs21CenturyFeatured}
        pasthighsheriffs21Century={post.frontmatter.pasthighsheriffs21Century}
        pasthighsheriffs20Century={post.frontmatter.pasthighsheriffs20Century}
        pasthighsheriffs19Century={post.frontmatter.pasthighsheriffs19Century}
        pasthighsheriffs18Century={post.frontmatter.pasthighsheriffs18Century}
        pasthighsheriffs1417Century={post.frontmatter.pasthighsheriffs1417Century}
        />
    </Layout>
  );
};

PastHighSheriffsPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default PastHighSheriffsPage;

export const PastHighSheriffsPageQuery = graphql`
  query PastHighSheriffsPageTemplate {
    markdownRemark(frontmatter: {templateKey: {eq: "pasthighsheriffs-page"}}) {
      frontmatter {
        heading
        currenthighsheriffname
        currenthighsheriffbio
        currenthighsheriffimage {
          childImageSharp {
            gatsbyImageData(
              quality: 100
            )
          }
        }
        pasthighsheriffs21CenturyFeatured {
          name
          bio
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
              )
            }
          }
        }
        pasthighsheriffs21Century
        pasthighsheriffs20Century
        pasthighsheriffs19Century
        pasthighsheriffs18Century
        pasthighsheriffs1417Century
      }
    }
  }
`;
